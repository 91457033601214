import React from "react"
import Index from "@pages/thanks-newsletter.js";

const Thanks_newsJa = () => {
  const setLang = 'ja';
  return (
  <><Index langType={setLang}/></>
  )
}
export default Thanks_newsJa
